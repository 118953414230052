/**
 * Lazysizes
 * @description Sets up lazyloading/responsive images
 */

/**
 * Module Dependencies
 */
var $ = jQuery = require( 'jquery' ),
	Util = require( './util' ),
	LazysizesParentFit = require('lazysizes/plugins/parent-fit/ls.parent-fit'),
	LazysizesBgSet = require('lazysizes/plugins/bgset/ls.bgset'),
	LazysizesRespImg = require('lazysizes/plugins/respimg/ls.respimg'),
	LazysizesAspectRatio = require('lazysizes/plugins/aspectratio/ls.aspectratio'),
	LazysizesObjectFit = require('lazysizes/plugins/object-fit/ls.object-fit'),
	Lazysizes = require('lazysizes'),
	allLazyLoaded = require('../lib/jquery.allLazyLoaded');

/**
 * Module Vars
 */
var name = "Lazysizes",
	debugEnabled = true,
	debug = debugEnabled ? Util.debug : function () {},
	$window = $(window),
	$lazyloadElements = {};

/**
 * Initializes Module
 */
function init() {
	debug('init(): ' + name);

	window.lazySizesConfig.expand = 2400;

	setVariables();
	bindPjaxEvents();

}

function setVariables() {

	$lazyloadElements = $('.lazyload');
	bindEvents();

}

function bindPjaxEvents() {

	// re-initialize after page transition
	document.addEventListener('swup:pageView', setVariables);

}

function bindEvents() {

	$lazyloadElements.on('lazybeforeunveil', onLazyloaded);

}

function onLazyloaded() {
	
	var $this = $(this),
		$container = $(this).parent();

	// initialize Vimeo player instance if Vimeo iframe
	// if ( $this.hasClass('vimeo-iframe') ) {

	// 	// set iframe src attribute
	// 	$this.attr('src', $this.attr('data-src') );
			
	// 	// initialize Vimeo player
	// 	Vimeo.initializeVimeoVideo($container);

	// }

}


/**
 * Module Exports
 */
module.exports = {
	init: init
};
/**
 * Executes a function (fn)  if all lazySizes images are loaded and trigger an event called `containerlazyloaded`
 * 
 * @param [fn] {function}
 * @return {jQuery}
 * 
 * @example
 *
 *	$('.scroll-view').allLazyLoaded(function(){
 *		console.log(this)
 *	});
 * @example
 *
 *	$('.scroll-view').allLazyLoaded().on('containerlazyloaded', function(){
 *		console.log(this)
 *	});
 */
jQuery.fn.allLazyLoaded = function(fn){
	if(this.length){
		var loadingClass = '.' + lazySizes.cfg.loadingClass;
		var toLoadClass = '.' + lazySizes.cfg.lazyClass;
		var isComplete = function(){
			return !('complete' in this) || this.complete;
		};

		this.each(function(){
			var container = this;
			var testLoad = function(){
				if(!$(toLoadClass, container).length && !$(loadingClass, container).not(isComplete).length){
					container.removeEventListener('load', rAFedTestLoad, true);
					if(fn){
						fn.call(container, container);
					}
					$(container).trigger('containerlazyloaded');
				}
			};
			var rAFedTestLoad = function(){
				requestAnimationFrame(testLoad);
			};

			container.addEventListener('load', rAFedTestLoad, true);
			rAFedTestLoad();
		});
	}
	return this;
};
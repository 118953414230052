/**
 * Slick
 * @description Sets up slideshows
 */

/**
 * Module Dependencies
 */
var $ = require( 'jquery' ),
	Util = require( './util' ),
   	slick = require( '../lib/slick-carousel' );

/**
 * Module Vars
 */
var name = "Slick",
	debugEnabled = true,
	debug = debugEnabled ? Util.debug : function () {},
	$window = $(window);

/**
 * Initializes Module
 */
function init() {
	debug('init(): ' + name);

	slickInit();
	bindPjaxEvents();

}

/**
 * Initializes Slick
 */
function slickInit() {
	// debug('slickInit()');
	
	// initialize
	$('.slick-carousel').slick();

	// bind slick events
	bindSlickEvents();

}

/**
 * Bind page transition events
 */
function bindPjaxEvents() {

	// re-initialize after page transition
	document.addEventListener('swup:pageView', slickInit);

}

/**
 * Binds Slick Events
 */
function bindSlickEvents() {

	$('.slick-carousel .slick-slide').on('click', goToNextSlide);

}

/**
 * Advances Slideshow
 */
function goToNextSlide() {

	$targetSlideshow = $(this).parents('.slick-carousel');
	$targetSlideshow.slick('slickNext');

}

/**
 * Module Exports
 */
module.exports = {
	init: init
};

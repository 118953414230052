'use strict';

module.exports = function (element, to, duration) {
    var _this = this;

    var body = document.body;

    var UP = -1;
    var DOWN = 1;

    var friction = 0.7;
    var acceleration = 0.04;

    var positionY = 100;
    var velocityY = 0;
    var targetPositionY = 400;

    var raf = null;

    function getScrollTop() {
        return document.body.scrollTop || document.documentElement.scrollTop;
    }

    var animate = function animate() {
        var distance = update();
        render();

        if (Math.abs(distance) > 0.1) {
            raf = requestAnimationFrame(animate);
        } else {
            _this.triggerEvent('scrollDone');
        }
    };

    function update() {
        var distance = targetPositionY - positionY;
        var attraction = distance * acceleration;

        applyForce(attraction);

        velocityY *= friction;
        positionY += velocityY;

        return distance;
    }

    var applyForce = function applyForce(force) {
        velocityY += force;
    };

    var render = function render() {
        window.scrollTo(0, positionY);
    };

    window.addEventListener('mousewheel', function (event) {
        if (raf) {
            cancelAnimationFrame(raf);
            raf = null;
        }
    }, {
        passive: true
    });

    var scrollTo = function scrollTo(offset, callback) {
        positionY = getScrollTop();
        targetPositionY = offset;
        velocityY = 0;
        animate();
    };

    this.triggerEvent('scrollStart');
    if (duration == 0) {
        window.scrollTo(0, 0);
        this.triggerEvent('scrollDone');
    } else {
        scrollTo(to);
    }
};
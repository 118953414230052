/**
 * Utilities
 * @description Brief Description
 */

/**
 * Module Dependencies
 */
var $ = require( 'jquery' );

/**
 * Module Vars
 */
var name = 'Util',
	debug = function () {};

/**
 * Debug - console.log wrapper
 *
 * @param mixed obj
 */
debug = function () {

	if ( typeof console !== 'object' || ! console.log ) {
		return;
	}

	return Function.prototype.bind.call( console.log, console );

}();

/**
 * Set window.requestAnimationFrame
 *
 * requestAnimationFrame Firefox 23 / IE 10 / Chrome / Safari 7 (incl. iOS)
 * mozRequestAnimationFrame Firefox < 23
 * webkitRequestAnimationFrame Older versions of Safari / Chrome
 */
( function setWindowRequestAnimationFrame() {

	window.requestAnimationFrame = window.requestAnimationFrame ||
		window.mozRequestAnimationFrame ||
		window.webkitRequestAnimationFrame ||
		window.oRequestAnimationFrame;

} )();

/**
 * Does the current browser support touch?
 *
 * @see https://hacks.mozilla.org/2013/04/detecting-touch-its-the-why-not-the-how/
 *
 * @return {Boolean}
 */
function isTouch() {

	return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

}

/**
 * Is this a mobile device?
 * This might be too clever.
 *
 * @return {Boolean}
 */
function isMobile() {

	return 'orientation' in window;

}

/**
 * Module Exports
 */
module.exports = {
	debug:      debug,
	isTouch:    isTouch,
	isMobile:   isMobile
};

/**
 * Scroll Lock
 * @description Sets up body scroll lock functionality
 */

/**
 * Module Dependencies
 */
var $ = require( 'jquery' ),
	Util = require( './util' ),
	bodyScrollLock = require( 'body-scroll-lock' ),
	disableBodyScroll = bodyScrollLock.disableBodyScroll;
	enableBodyScroll = bodyScrollLock.enableBodyScroll;

/**
 * Module Vars
 */
var debugEnabled = true,
	debug = debugEnabled ? Util.debug : function () {},
	name = 'ScrollLock',
	$window = $(window);

/**
 * Initializes Module
 */
function init() {
	debug('init(): ' + name);

	bindPjaxEvents();

}

/**
 * Bind events
 */
function bindPjaxEvents() {

	// disable scroll after barba page transition
	// $window.on('barba:linkClicked', disableBodyScroll);

	// re-enable scroll after page transition
	document.addEventListener('swup:pageView', enableBodyScroll);

}

/**
 * Module Exports
 */
module.exports = {
	init: init,
	disableBodyScroll: disableBodyScroll,
	enableBodyScroll: enableBodyScroll
};
/**
 * Swup
 * @description Sets up swup pjax library
 */

/**
 * Module Dependencies
 */
var $ = require( 'jquery' ),
	Util = require( './util' ),
	Swup = require( 'swup' ).default;

/**
 * Module Vars
 */
var name = 'Swup',
	debugEnabled = true,
	debug = debugEnabled ? Util.debug : function () {},
	$body = $( 'body' ),
	$window = $( window );

/**
 * Initializes Module
 */
function init() {
	debug('init(): ' + name);

	var swup = new Swup({
		debugMode: false,
		LINK_SELECTOR: 'a[href^="/"]:not([data-no-swup]), a[href^="' + window.location.origin + '"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])'
	});

}

/**
 * Module Exports
 */
module.exports = {
	init: init
};
'use strict';

var forEach = Array.prototype.forEach;


module.exports = function (url, popstate) {
    var _this = this;

    var finalPage = null;

    // scrolling
    if (this.options.doScrollingRightAway && !this.scrollToElement) {
        this.doScrolling(popstate);
    }

    var animationPromises = [];

    if (!popstate) {
        // start animation
        document.documentElement.classList.add('is-changing');
        document.documentElement.classList.add('is-leaving');
        document.documentElement.classList.add('is-animating');
        document.documentElement.classList.add('to-' + this.classify(url));

        // detect animation end
        var animatedElements = document.querySelectorAll(this.options.animationSelector);
        forEach.call(animatedElements, function (element) {
            var promise = new Promise(function (resolve) {
                element.addEventListener(_this.transitionEndEvent, resolve);
            });
            animationPromises.push(promise);
        });

        Promise.all(animationPromises).then(function () {
            _this.triggerEvent('animationOutDone');
        });

        // create pop element with or without anchor
        if (this.scrollToElement != null) {
            var pop = url + this.scrollToElement;
        } else {
            var pop = url;
        }
        this.createState(pop);
    } else {
        // proceed without animating
        this.triggerEvent('animationSkipped');
    }

    if (this.cache.exists(url)) {
        var xhrPromise = new Promise(function (resolve) {
            resolve();
        });
        this.triggerEvent('pageRetrievedFromCache');
    } else {
        if (!this.preloadPromise || this.preloadPromise.route != url) {
            var xhrPromise = new Promise(function (resolve) {
                _this.getPage(url, function (response) {
                    if (response === null) {
                        console.warn('Server error.');
                        _this.triggerEvent('serverError');
                        _this.goBack();
                    } else {
                        // get json data
                        var page = _this.getDataFromHtml(response);
                        page.url = url;
                        // render page
                        _this.cache.cacheUrl(page, _this.options.debugMode);
                        _this.triggerEvent('pageLoaded');
                    }
                    resolve();
                });
            });
        } else {
            var xhrPromise = this.preloadPromise;
        }
    }

    Promise.all(animationPromises.concat([xhrPromise])).then(function () {
        finalPage = _this.cache.getPage(url);
        if (!_this.options.cache) {
            _this.cache.empty(_this.options.debugMode);
        }
        _this.renderPage(finalPage, popstate);
        _this.preloadPromise = null;
    });
};
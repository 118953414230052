'use strict';

var _Link = require('../Link');

var _Link2 = _interopRequireDefault(_Link);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

module.exports = function (eventName) {
    var _this = this;

    if (this.options.preload) {
        var preload = function preload(pathname) {
            var link = new _Link2.default();
            link.setPath(pathname);
            if (link.getAddress() != _this.currentUrl && !_this.cache.exists(link.getAddress()) && _this.preloadPromise == null) {
                _this.getPage(link.getAddress(), function (response) {
                    if (response === null) {
                        console.warn('Server error.');
                        _this.triggerEvent('serverError');
                    } else {
                        // get json data
                        var page = _this.getDataFromHtml(response);
                        page.url = link.getAddress();
                        _this.cache.cacheUrl(page, _this.options.debugMode);
                        _this.triggerEvent('pagePreloaded');
                    }
                });
            }
        };

        document.querySelectorAll('[data-swup-preload]').forEach(function (element) {
            preload(element.href);
        });
    }
};
// Require modules
var WindowEvents = require( './modules/window-events' );
var Swup = require( './modules/swup' );
var Lazysizes = require( './modules/lazysizes' );
var ScrollLock = require( './modules/scroll-lock' );
var Slick = require( './modules/slick' );

// Initialize modules 
WindowEvents.init();
Swup.init();
Lazysizes.init();
ScrollLock.init();
Slick.init();


'use strict';

module.exports = function (html) {
    var _this = this;

    var content = html.replace('<body', '<div id="swupBody"').replace('</body>', '</div>');
    var fakeDom = document.createElement('div');
    fakeDom.innerHTML = content;
    var blocks = [];

    for (var i = 0; i < this.options.elements.length; i++) {
        if (fakeDom.querySelector(this.options.elements[i]) == null) {
            console.warn('Element ' + this.options.elements[i] + ' is not found cached page.');
        } else {
            [].forEach.call(document.body.querySelectorAll(this.options.elements[i]), function (item, index) {
                fakeDom.querySelectorAll(_this.options.elements[i])[index].dataset.swup = blocks.length;
                blocks.push(fakeDom.querySelectorAll(_this.options.elements[i])[index].outerHTML);
            });
        }
    }

    var json = {
        title: fakeDom.querySelector('title').innerText,
        pageClass: fakeDom.querySelector('#swupBody').className,
        originalContent: html,
        blocks: blocks
    };
    return json;
};
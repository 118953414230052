/**
 * Window Events
 * @description Throttles scroll and resize window events
 */

/**
 * Module Dependencies
 */
var $ = require( 'jquery' ),
	_ = require( 'underscore' ),
	Util = require( './util' );

/**
 * Module Vars
 */
var debugEnabled = true,
	debug = debugEnabled ? Util.debug : function () {},
	name = 'WindowEvents',
	initialized = false,
	$window = $( window ),
	$body = $( 'boyd' );

/**
 * Initializes Module
 */
function init() {

	debug( 'init(): ' + name );

	if ( Util.isMobile() ) {
		$('html').addClass('is-mobile');
	} else {
		$('html').addClass('is-desktop');
	}

	var initialized = false,
		$window = $( window )
	;

	if ( initialized ) return;

	var scrollHandler = null,
		resizeHandler = null
	;

	if ( window.requestAnimationFrame ) {

		scrollHandler = function () {
			window.requestAnimationFrame( onScrollEvent );
		};

		resizeHandler = function () {
			window.requestAnimationFrame( onResizeEvent );
		};

	} else {

		// Add throttling/debouncing if requestAnimationFrame isn't available
		// @see: http://underscorejs.org/

		scrollHandler = _.throttle( onScrollEvent, 20 );
		resizeHandler = _.debounce( onResizeEvent, 20 );

	}

	$window
		.on( 'scroll', scrollHandler )
		.on( 'resize', resizeHandler )
		.on( 'load', onLoad )
	;

	initialized = true;

}

//////////////////////////////////////////////////////////////////////////////////////

function onScrollEvent() {

	$window.trigger( 'Scroll.' + name , {
		scrollTop: $window.scrollTop()
	} );

}

//////////////////////////////////////////////////////////////////////////////////////

function onResizeEvent() {

	$window.trigger( 'Resize.' + name );

}

//////////////////////////////////////////////////////////////////////////////////////

function onLoad() {

	$body.addClass('page-loaded');

}

/**
 * Module Exports
 */
module.exports = {
	init: init
};

